import profDesign from '../Profile Design Transparent.png'
import markerDesign from '../Marker View Transparent.png'
import chatDesign from '../Chat View Image.png'
import resultDesign from '../Result View Image.png'

function AboutPageMobile() {
    return (
        <div className="About-container-mobile">
            <div className="About-page-entry-mobile">
                <div className='About-page-text-mobile' id="default">
                <h1>The dating app to end all dating apps.</h1>
                    <p>
                        Today, dating apps suck. But they don't have to. Geomatchry will be the first friendly, thoughtful dating app. Profiles are simple to setup,
                        matches are intentional, and connections are meaningful. How does it work? To use Geomatchry, you "mark"
                        your current location, and that allows you to match with others who have done the same. Use markers as an opportunity to connect with someone you saw,
                        meet new people at a certain place, or just explore what's around you. There's no algorithms or games interfering with your experience - just Geomatchry.
                    </p>
                </div>
            </div>

            <div class="About-page-image">
                <img src={markerDesign} alt=""/>
            </div>

            
            <div className="About-page-entry-mobile">
                <div className='About-page-text-mobile' id="porange">
                    <h1>Make a profile in a minute. And never touch it again.</h1>
                    <p>
                        With other apps, you're never done making your profile. Instead, you're <i>constantly</i> trying to cultivate
                        your profile and appear like the best version of yourself to get more matches. And by the end of it, you don't even recognize yourself.
                        Not with Geomatchry. To make an account with us, you only need to provide one image, a brief bio, school, and job.
                        Gone are the days of endless tweaking and tuning - and wondering if others really do look like their profile picture. 
                        At Geomatchry, we keep profiles stress-free and real.
                    </p>
                </div>
            </div>
            <div class="About-page-image">
                <img src={profDesign} alt=""/>
            </div>

            
            <div className="About-page-entry-mobile">
                <div className='About-page-text-mobile' id="blellow">
                    <h1>Mark your location, and turn chance encounters into something more.</h1>
                    <p>
                        It was Geomatchry at first sight. Run into a nice person at the café? See someone cute at the gym? Isn't that person in the library <i>constantly</i> checking you out?
                        Get all these questions asked and answered. With Geomatchry, it's simple: just leave a marker at your current location. If you see the person
                        you're looking for, match with them. If not, check in on the results of the marker a little bit later. Don't let these sparks keep dying out -
                        with Geomatchry, turn chance encounters into something more.
                    </p>
                </div>
            </div>

            <div class="About-page-image">
                <img src={resultDesign} alt=""/>
            </div>

            
            <div className="About-page-entry-mobile">
                <div className='About-page-text-mobile' id="retro">
                    <h1>Connect with your matches. No algorithms, just Geomatchry.</h1>
                    <p>
                        With Geomatchry, you only match with people when you want to. If you don't have active markers, nobody sees your profile. 
                        Show up when - and where - you want to. And when people mark the same location, they can <b>always</b> see each other.
                        Nothing can get in the way of your matches anymore - Geomatchry keeps it simple and genuine. 
                    </p>
                </div>
            </div>

            <div class="About-page-image">
                <img src={chatDesign} alt=""/>
            </div>

            <div className="About-page-entry-mobile">
                <div className='About-page-final-text-mobile'>
                    <h2>
                        Geomatchry - the dating app to end all dating apps. Coming to select colleges later this summer. 
                        Join our mailing list to stay in the loop on our release, or sign up for our exclusive beta test this July.
                        And check us out on Instagram - @geomatchry.
                    </h2>
                </div>
            </div>
        </div> 
    );
}

export default AboutPageMobile;