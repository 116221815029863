import './App.css';
import Route from './components/Route';
import AboutPage from './pages/AboutPage';
import HomePage from './pages/HomePage';
import NavButton from './components/NavButton';
import AboutPageMobile from './pages/AboutPage Mobile';
import icon from './Geomatchry Icon-Newest.png';


function App() {
  return (
    <div className="App">
        <header className="App-header">
          <div className="App-name">
            <span>Geomatchry</span>
            <img src={icon} alt="The Geomatchry app icon"></img>
          </div>
          <div className="Nav-buttons">
            <NavButton linksTo={"/"}>Home</NavButton>
            <NavButton linksTo={"/about"}>About</NavButton>
          </div>
        </header>

        <div className="page-content">
          <Route path="/">
              <HomePage />
          </Route>
        </div>
        <div className='about-page-desktop'>
          <Route path="/about">
            <AboutPage />
          </Route>
        </div>
        <div className='about-page-mobile'>
          <Route path="/about">
            <AboutPageMobile />
          </Route>
        </div>
    </div>
  )
  
}

export default App;
