import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAWn5Bz1FEOV9RMrRmEXYFJgzHbRq5NpeQ",
  authDomain: "geomatchry.firebaseapp.com",
  projectId: "geomatchry",
  storageBucket: "geomatchry.appspot.com",
  messagingSenderId: "70666176605",
  appId: "1:70666176605:web:ee3c0d2c44e89d7823a5b4",
  measurementId: "G-8JHVT7DHG7"
};

const app = initializeApp(firebaseConfig);

export default app;