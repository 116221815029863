import icon from '../Geomatchry Icon-Newest.png';

// React imports
import { useState } from "react";

// Firebase imports from config/functions
import { getFirestore, doc, arrayUnion, updateDoc} from "firebase/firestore";
import app from "../firebase.config"
const db = getFirestore(app);

function HomePage() {
  const [userEmail, setUserEmail] = useState("");
  const [beta, setBeta] = useState(false);
  const [news, setNews] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (event) => {
    setUserEmail(event.target.value)
  }

  const betaPressed = (event) => {
    setBeta(!beta)
  }

  const newsPressed = (event) => {
    setNews(!news)
  }

  const submitPressed = (event) => {
    event.preventDefault();

    const emailDoc = doc(db, "emailList", "emailList");
    
    // very small formatting check
    if (userEmail.includes("@") && userEmail.includes(".")) {
      updateDoc(emailDoc, {
        "signups": arrayUnion({
          "email": userEmail,
          "beta": beta,
          "updates": news
        })
      }).then(() => {
        setSuccessMessage("Great. You're on the list.");
        setUserEmail("");
        setBeta(false);
        setNews(false);
      }, 
      // callback function for failure of promise
      () => {
        setSuccessMessage("That didn't quite work. Try again.");
      })
    } else {
      setSuccessMessage("That didn't quite work. Try again.");
    }
  }
  
    return (
        <div className='Home-content'>
            <p className="App-title">Geomatchry</p>
            <p>The dating app to end all dating apps.</p>
            <img src={icon} className="App-logo" alt="logo" />
            <p>
              Join our mailing list today and get notified when the app is released. <br></br>
              Exclusive beta test this July. Coming to select colleges this fall.
            </p>

            <h5>{successMessage}</h5>
            <span className="input-span">
              <input className="user-text-input" value={userEmail} onChange={handleChange} placeholder="Your email here..."></input>
              <button className='user-button' onClick={submitPressed}>Join</button>
            </span>

            <div className="container">
              <div>
                  <input type="checkbox" checked={beta} onClick={betaPressed} className="user-checkbox" />
                  <label for="beta" className="user-option-desc">Interested in Beta</label>
              </div>
              <div>
                  <input type="checkbox" checked={news} onClick={newsPressed} className="user-checkbox" />
                  <label for="news" className="user-option-desc">Sign me up for news and updates</label>
              </div>
            </div>

            {/* Contact - Instagram + Email */}
            <span>
              {/* TODO - open in separate tab using inline JS function */}
              <a href='https://www.instagram.com/geomatchry/'>Instagram</a>
              &nbsp;| support@geomatchry.com 
            </span>
        </div>
      );
}

export default HomePage;