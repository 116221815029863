import useNavigation from "../hooks/use-navigation";

function NavButton({ linksTo, children }) {
    const { navigate, currentPath } = useNavigation();

    const currentClassName = currentPath === linksTo ? "nav-button-active" : "nav-button"

    const handleClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
          return;
        }
    
        event.preventDefault();
    
        navigate(linksTo);
    };

    return (
        <a className={currentClassName} href={linksTo} onClick={handleClick}>
            { children }
        </a>
    );
}

export default NavButton;